import React from 'react';

import { createPromise } from '../promises';
import { GuardedCaptcha } from './GuardedCaptcha';
import { CaptchaGuardedRequest, CaptchaGuardedResult } from './types';

/**
 * Small wrapper around GuardedCaptcha that turns it into an imperative function.
 * @see GuardedCaptcha
 */
export function sendCaptchaGuardedRequest<Result>(
  props: CaptchaGuardedRequest<Result>
) {
  const { innerPromise, resolve } = createPromise<
    CaptchaGuardedResult<Result>
  >();

  return [
    <GuardedCaptcha {...props} resolve={resolve} />,
    innerPromise,
  ] as const;
}
